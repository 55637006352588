body{
    font-family: "Lato"!important;
    font-family: -webkit-body!important;
   font-family: -moz-body!important;
   -moz-font-feature-settings: 'Lato';
   font-style: normal;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
}
.font-open-sans{
    font-family: "Lato"!important;
}
#form_pan {
    margin: 0 auto;
}

#signup-main-dv,
#login-main-dv,
#enter-thecode-dv,
#select-agency-dv,
#invitesent-dv,
#invitepepople-dv,
#groupchat-dv,
#entercode-dv,
#createagency-dv {
    height: 100vh;
    padding: 0px;
    margin: 0px;
}

#create-group-dv {
    margin: 0px;
    padding: 0px;
    height: inherit;
    overflow: hidden;
}

#left_sidebar {
    height: 100vh;
}

.inner-left_sidebar {
    overflow-y: scroll;
    height: 100vh;
}

.margin-auto-dv {
    margin: 0 auto;
}

.add-people-nav {
    padding: 0px 15px;
}

#add-people-nav {
    padding: 0px 15px !important;
}

.responsive-overflow {
    overflow: inherit;
    min-width: 100%;
}

#signup_form>div:first-of-type input:first-child {
    width: 16%;
}

#signup_form>div:first-of-type input:nth-child(2) {
    width: 84%;
}

#textarea-dv {
    height: auto;
}

#content_pan {
    min-height: 100vh;
}

#content_pan {
    height: 100vh;
}

#signup-main-dv #content_pan {
    min-height: 100vh !important;
}

#create_agency #agency-nav {
    border: 1px solid rgb(86 117 128 / 10%);
    border-left: none;
    border-right: none;
    border-top: none;
}

#right-sidebar {
    overflow-y: scroll;
}

#tab_cotainer .tab_cotainer-dv {
    border: 1px solid rgb(86 117 128 / 10%);
    border-left: none;
    border-right: none;
    border-top: none;
}

#sidebar_header {
    border: 1px solid rgb(86 117 128 / 10%);
}

#block_board,
#block_group {
    border: 1px solid rgb(86 117 128 / 10%);
    border-top: none;
}

#create_agency #agency-nav img {
    width: 50%;
}

#content_pan>div:first-child {}

#content_pan>div:nth-child(2) {
    height: calc(100% - 80px - 80px);
    overflow: auto;
}

.createagency-footer {
    display: block;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 5px;
}

.groupchat-dv #content_pan>div:last-child {}

form .radio-input {
    background: #09B7AA !important;
}

#select_agency,
#select-agency-dv {
    height: 100vh;
}

#select-agency-dv {
    height: 100vh !important;
}

#select_agency>div:nth-child(2) {
    height: calc(100% - 109px - 122px);
    overflow: auto;
}

#inner-select-agency-blg {
    height: calc(100% - 109px - 122px);
    overflow: auto;
}

#block_people>div:nth-child(2)>* div {
    display: flex;
    justify-content: space-between;
}

#left_sidebar section {
    height: calc(100% - 109px - 122px);
    overflow: auto;
}

.addpeople-dv {
    height: 618px;
    overflow-y: scroll;
}
#block_people input{
    border:1px solid #ddd!important;
    outline: none!important;
}
.message-design-bg{
    /* background-color: #f2f2f2; */
    background-color: rgb(245 245 245);
    border-radius: 8px;
    padding: 8px;
}
.custom-border-img{
    border:1px solid #ddd!important;
}
div#inner-chat-blg {
    display: flex;
    flex-wrap: wrap;
    /* table-layout: unset; */
    max-width: max-content;
}

@media (max-width:992px) {
    #right-sidebar .addpeople-dv {
        min-height: 100% !important;
        overflow-y: scroll;
    }
}

@media (max-width:900px) {

    #right-sidebar {
        min-width: 320px;
        position: absolute;
        right: 0px;
    }
}

@media (min-width:768px) {
    #form_pan {
        margin: 0 auto;
    }

    #createagency-dv,
    #enter-thecode-dv,
    #entercode-dv,
    #groupchat-dv,
    #invitepepople-dv,
    #invitesent-dv,
    #login-main-dv,
    #select-agency-dv,
    #signup-main-dv {
        height: inherit;
    }
}

@media (max-width:480px) {
    #select_agency {
        min-width: 320px !important;
    }

    #invite_people {
        min-width: 320px !important;

    }

    .message-design-bg {
        width: -webkit-fill-available;
        word-wrap: break-word;
    }
    .message-design-bg span{
        min-width: -webkit-fill-available!important;
        word-wrap: break-word;
    }
    .message-design-bg span a{
        word-wrap: break-word;
    }

    #content_pan {
        width: 320px !important;
    }

    #right-sidebar {
        min-width: 300px;
        position: absolute;
        right: 0px;
        padding-top: 25px;
    }

    #right-sidebar>#right-sidebar {
        margin-top: 10px;
    }

    #right-sidebar #right-sidebar:first-of-type {
        padding-top: 0px;
    }

    #right-sidebar .addpeople-dv {
        max-height: 94% !important;
        overflow-y: scroll;
    }

    #left_sidebar {
        min-width: 100% !important;
    }

    #invitation-sent-content {
        width: 320px !important;
    }

    #content_pan_header>button {
        width: 15px;
        height: 15px;
    }

    #left_sidebar {
        width: 100%;
    }

    #left_sidebar {
        height: auto;
    }

    .responsive-overflow {
        overflow: inherit;
    }

    .people-hgt-dv {
        height: auto;
        min-width: 320px;

    }

    #create_group>* {
        min-width: 300px;
    }

    .people-hgt-blg {
        height: auto;
    }

    .people-hgt-main {
        height: auto;
    }

    #createagency-dv,
    #enter-thecode-dv,
    #entercode-dv,
    #groupchat-dv,
    #invitepepople-dv,
    #invitesent-dv,
    #login-main-dv,
    #select-agency-dv,
    #signup-main-dv {
        height: inherit;
        padding: 0px;
        margin: 0px;
    }

    #signup_form>div:first-of-type input:first-child {
        width: 16%;
    }

    #signup_form>div:first-of-type input:nth-child(2) {
        width: 84%;
    }

    #signup-main-dv #form_pan {
        margin: 0 auto;
        padding: 20px;
    }

    #signup-main-dv #content_pan {
        min-height: inherit !important;
        padding: 25px;
    }
}




@media only screen and (min-device-width: 320px) and (max-device-width: 740px) {
    #tab_conent_invite {
        min-width: 320px;
        padding: 10px;
    }

    #invite_people {
        min-width: 320px;
    }

    #content_pan {
        min-height: auto !important;
    }

    #createagency-dv,
    #enter-thecode-dv,
    #entercode-dv,
    #groupchat-dv,
    #invitepepople-dv,
    #invitesent-dv,
    #login-main-dv,
    #select-agency-dv,
    #signup-main-dv {
        height: inherit !important;
        padding: 0px;
        margin: 0px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width:991px) and (orientation: landscape) {
    #tab_conent_invite {
        min-width: 320px;
        padding: 10px;
    }

    #invite_people {
        min-width: 320px;
    }

    #content_pan {
        min-height: auto !important;
    }

    #createagency-dv,
    #enter-thecode-dv,
    #entercode-dv,
    #groupchat-dv,
    #invitepepople-dv,
    #invitesent-dv,
    #login-main-dv,
    #select-agency-dv,
    #signup-main-dv {
        height: inherit !important;
        width: 100%;
        padding: 0px;
        margin: 0px;
    }

    #content_pan>div:nth-child(2) {
        height: calc(100% - 80px - 130px - 130px);
        overflow: auto;
    }

    #left_sidebar {
        height: inherit;
        width: 100%;
    }
}

.pointercursor {
    cursor: pointer;
}

.disabled {
    opacity: 0.5;
    /* Example: reduce opacity for disabled state */
    cursor: not-allowed;
    /* Example: change cursor for disabled state */
}

.text-16 {
    font-size: 16px;
}

.w-\[1080px\].chatMain {
    width: calc(100% - 360px);
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    visibility: hidden;
    width: 100px;
    background-color: rgb(221, 225, 233);
    color: #565454;
    text-align: center;
    border-radius: 6px;
    padding: 4px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 90%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: medium;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

input {
    font-family: "Lato"!important;
    font-size: 16px!important;
    color: rgb(61 116 143 / var(--tw-text-opacity))!important;
}