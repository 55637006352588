@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato"!important;
  font-family: -webkit-body!important;
  font-family: -moz-body!important;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace!important; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */